import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import { Button } from '@material-ui/core'

import { Loader, Layout } from '../../../components'
import MyBoat from './MyBoat'
import SurveyMyBoat from './SurveyMyBoat'
import SurveyReport from './SurveyReport'
import Payment from './Payment'
import Shipment from './Shipment'

import '../SalesEngine.scss'
import '../SalesEngineResponsive.scss'

import {
  getSingleSalesEngine,
  getCostEstimate,
  addSurveyOption,
  salesEngineAgents,
  stopSalesEngineProcess,
  getSalesEngineStaticContent,
  salesEngineStepChange,
  salesEnginPaymentInfo,
} from '../../../redux/actions'
import { SuccessNotify } from '../../../helpers/notification'
import {
  getMySalesEngineStatus,
  getSalesEngineCountDescription,
  salesEngineStatus,
  salesEngineStatusCheck,
} from '../SalesEngineHelper'
import { CommonSalesEnginTitle } from '../../../components/salesEngine/CommonSalesEnginTitle'
import {
  boatSoldMessage,
  disableStepperButtonMessage,
  salesEngineAuctionWinnerText,
  SalesEngineStepPaymentType,
  selectBuyer,
  stepTypeEnum,
  userRoles,
  wishlistModuleEnum,
} from '../../../util/enums/enums'
import { getLocalStorageItem } from '../../../helpers/storageHelper'
import { isSurveyorAvailable } from '../../../helpers/jsxHelper'
import { CommonTooltip } from '../../../components/CommonTooltip'
import { confirmSubmitHandler } from '../../../helpers/confirmationPopup'
import { viewBoatHandler } from '../../../helpers/boatHelper'
import { Countdown } from '../../../components/home/countdown'
import { CommonModal } from '../../../components/modal/commonModal'
import { ButtonComponent } from '../../../components/form/Button'
import { FaArrowRight, FaQrcode } from 'react-icons/fa'
import { getModulePdf } from '../../../redux/actions/pdfAction'
import { getId } from '../../../util/utilFunctions'
import { ArrowRight, ArrowRightAlt, ArrowRightAltOutlined } from '@material-ui/icons'
import IconSEArrowRight from './right-arrow-svgrepo-com'
import IconSEArrowLeft from './left-arrow-svgrepo-com'

class SalesEngineProcess extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sellerUser: false,
      stopProcessModel: false,
      stepperWidth: 0,
      hrWidthCalc: 0,
      divWidthCalc: 0,
    }
  }

  componentDidMount() {
    const {
      getSingleSalesEngine,
      getCostEstimate,
      match: { params },
      salesEngineAgents,
      salesEngine,
      getSalesEngineStaticContent,
      salesEnginPaymentInfo,
    } = this.props
    getSalesEngineStaticContent()
    salesEngine && !salesEngine.agent && salesEngineAgents({ salesEngineId: params.id })

    getCostEstimate()
    if (params?.id) {
      getSingleSalesEngine({ id: params.id })
    }
  }

  stepperWidth = (salesEngine, salesEngineSteps) => {
    const { hrWidthCalc, divWidthCalc } = this.state
    const stepperWidth = salesEngine && salesEngineSteps && document.querySelector('.sales-engine-stepper')
    const stepperActualWidth =
      stepperWidth && stepperWidth.offsetWidth / (document.querySelectorAll('.stepper-info-rounded-circle').length - 1)
    !hrWidthCalc && stepperActualWidth && this.setState({ hrWidthCalc: stepperActualWidth + 5 })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      salesEngine,
      salesEnginPaymentInfo,
      match: { params },
    } = this.props

    if (
      prevProps.match.params?.id !== params?.id ||
      salesEngine?.salesEngineStatus !== prevProps.salesEngine?.salesEngineStatus ||
      salesEngine?.stepperInfo?.activeStep?.step !== prevProps.salesEngine?.stepperInfo?.activeStep?.step
    ) {
      salesEnginPaymentInfo({
        salesEngineId: params.id,
        transactionType: SalesEngineStepPaymentType[salesEngine?.stepperInfo?.activeStep?.step],
      })

      window.scrollTo({ top: 0 })
    }

    setTimeout(() => {
      salesEngine?.stepperInfo?.salesEngineSteps?.length &&
        this.stepperWidth(salesEngine, salesEngine.stepperInfo.salesEngineSteps)
    }, 500)
    window.addEventListener('resize', this.stepperWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.stepperWidth)
  }

  nextStepHandler = () => {
    const { salesEngineStepChange, salesEngine } = this.props

    salesEngineStepChange({ id: salesEngine.id, type: stepTypeEnum.next })
  }

  previousStepHandler = () => {
    const { salesEngineStepChange, salesEngine } = this.props

    salesEngineStepChange({ id: salesEngine.id, type: stepTypeEnum.previous })
  }

  stopProcessHandler = () => {
    const { stopSalesEngineProcess, salesEngine } = this.props
    stopSalesEngineProcess({ id: salesEngine?.id })
  }

  activeClassHandler = value => {
    const {
      salesEngine: { stepperInfo },
    } = this.props

    return stepperInfo?.activeStep?.step >= value ? 'stepper-selected-div-text stepper-line-color' : 'bg-white'
  }

  activeLineClassHandler = value => {
    const {
      salesEngine: { stepperInfo },
    } = this.props

    return stepperInfo?.activeStep?.step > value ? 'stepper-line-selected-color' : 'stepper-line-color-not-selected'
  }

  timerFunctionDetails = () => {
    const { isBuyer, isSeller, isAgent } = this.getProps()

    const {
      salesEngine: { surveyorPaymentDueTime, buyerPaymentDueTime },
    } = this.props

    if (surveyorPaymentDueTime) {
      return {
        title: 'Survey report will receive in',
        time: surveyorPaymentDueTime,
        desc: getSalesEngineCountDescription('surveyorPayment'),
        visiblePage: [2],
      }
    }

    if (buyerPaymentDueTime) {
      const descType = isBuyer ? 'buyerBoatPayment' : 'sellerBoatPayment'
      return {
        time: buyerPaymentDueTime,
        title: 'Time left to pay for boat',
        desc: getSalesEngineCountDescription(descType),
        visiblePage: [1, 2, 3, 4],
      }
    }

    return false
  }

  renderButtons = () => {
    const { salesEngine, currentUser, addSurveyOption } = this.props
    const { stepperInfo } = salesEngine

    return (
      <div className="sales-engine-buttons sales--engine--buttons">
        {currentUser?.id === salesEngine?.buyer?.id &&
        salesEngine.isSurveyorSkip &&
        stepperInfo?.activeStep?.step === 4 &&
        stepperInfo?.activeStep?.name === 'Boat Payment' &&
        isSurveyorAvailable(salesEngine.boat.boatStatus, salesEngine.seller?.role) &&
        !salesEngine.boatPayment &&
        !salesEngine?.isAuctionSalesEngine ? (
          <CommonTooltip
            component={ctProps => (
              <button
                data-tooltip-id={ctProps.id}
                data-tooltip-content="You can still go back and complete the boat survey if needed. Once the boat payment is completed, making changes to the survey will not be possible."
                type="button"
                className="btn rounded-md btn-o-black btn-o-ghost-black mr-20"
                onClick={() => addSurveyOption({ id: salesEngine.id, isSurveyorSkip: false })}
              >
                <IconSEArrowLeft style={{ height: '1em', marginRight: 10 }} /> {'Survey my boat'}
              </button>
            )}
          />
        ) : (
          stepperInfo?.activeStep?.step !== 1 && (
            <button
              type="button"
              name="previous"
              className="btn rounded-md btn-o-black btn-o-ghost-black mr-20"
              value="Previous"
              onClick={this.previousStepHandler}
              disabled={!stepperInfo?.previousStep?.isActivated}
            >
              <IconSEArrowLeft style={{ height: '1em', marginRight: 10 }} /> {stepperInfo?.previousStep?.name}
            </button>
          )
        )}

        {currentUser.id === salesEngine.buyer?.id &&
        !salesEngine.surveyorAccepted &&
        !salesEngine.surveyorPayment &&
        stepperInfo?.activeStep?.step === 2 &&
        !salesEngine.isSurveyorSkip ? (
          <button
            type="button"
            className="btn btn-o-black btn-o-ghost-black rounded-md"
            onClick={() => addSurveyOption({ id: salesEngine.id, isSurveyorSkip: true })}
          >
            {'Skip Survey & Go to Payment'} <IconSEArrowRight style={{ height: '1em', marginLeft: 10 }} />
          </button>
        ) : (
          stepperInfo?.nextStep && (
            <CommonTooltip
              component={ctProps => (
                <button
                  data-tooltip-id={ctProps.id}
                  data-tooltip-content={
                    !stepperInfo?.nextStep?.isActivated
                      ? String(stepperInfo?.nextStep?.name) === 'Shipment'
                        ? 'Complete the boat payment to proceed with picking up your boat or shipping it to your address'
                        : String(stepperInfo?.nextStep?.name) === 'Boat Payment'
                        ? 'To enable the boat payment, check the box below'
                        : disableStepperButtonMessage
                      : ''
                  }
                  type="button"
                  name="next"
                  className={`btn rounded-md ${
                    this.checkIfPaymentDue() ? 'btn-outline-danger' : 'btn-o-black btn-o-ghost-black'
                  }`}
                  disabled={
                    !stepperInfo?.nextStep?.isActivated || this.checkIfPaymentDue()
                    // (stepperInfo?.activeStep?.step === 2 && salesEngine && !salesEngine.surveyorReport) ||
                    // (stepperInfo?.activeStep?.step === 3 && salesEngine && !salesEngine.buyerReviewSurveyReport) ||
                    // (stepperInfo?.totalStep - 1 === stepperInfo?.activeStep?.step && (salesEngine && !salesEngine.buyerAgreement))
                  }
                  onClick={this.nextStepHandler}
                >
                  {String(stepperInfo?.nextStep?.name).replace(salesEngineStatus.surveyMyBoat, 'Start surveying my boat')}{' '}
                  <IconSEArrowRight style={{ height: '1em', marginLeft: 10 }} />
                </button>
              )}
            />
          )
        )}
      </div>
    )
  }

  checkIfPaymentDue = () => {
    const { salesEngine } = this.props
    const { stepperInfo } = salesEngine

    return (salesEngine?.isAuctionSalesEngine || stepperInfo?.activeStep?.step === 3) && salesEngine && salesEngine.isPaymentDue
  }

  renderTabs = salesEngine => {
    const { hrWidthCalc } = this.state
    const { stepperInfo } = salesEngine

    const steps = stepperInfo?.salesEngineSteps.filter(step =>
      step.step === 2 || step.step === 3 ? !salesEngine?.isSurveyorSkip : true
    )

    return (
      <div className="d-flex align-items-center width-100 pb-4 pt-4 sales-engine-stepper justify-content-between">
        {steps.map((step, index) => (
          <>
            <div className="position-relative stepper-main-div" key={step.name}>
              <div className="stepper-main-section" key={step.name}>
                <div
                  className=" d-flex justify-content-center align-items-start flex-direction-column width-100"
                  ref={this.stepperFunction}
                >
                  <div
                    className={`stepper-info-rounded-circle stepper-number-div stepper-number-div-new ${
                      step.isActivated && 'cursor-pointer'
                    } ${this.activeClassHandler(salesEngine?.isSurveyorSkip ? index + 3 : index + 1)} ${
                      (step.step === 2 || step.step === 3) && salesEngine?.isSurveyorSkip && `second--third--step--style`
                    } `}

                    // onClick={() => this.stepChangeHandler(index + 1, step)}
                  >
                    <span className="d-flex justify-content-center align-items-center h-100 font-14 stepper-div-text">
                      {step.name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {index < steps.length - 1 && (
              <hr className={`flex-1 stepper-line-div profile-hr ${this.activeLineClassHandler(index + 1)}`} />
            )}
          </>
        ))}
      </div>
    )
  }

  renderTitleWithButton = salesEngine => {
    const { stepperInfo, isPaymentDue, boatPayment, surveyorPaymentDueTime } = salesEngine
    const { isSeller, isBuyer, isAgent } = this.getProps()
    // const statusInfo = stepperInfo?.activeStep?.step === 1 ? stepperInfo?.activeStep?.name : salesEngine?.salesEngineStatus
    const statusInfo = salesEngine?.salesEngineStatus
    const countDownDetails = this.timerFunctionDetails()
    const checkBoatPaymentStatus = !isPaymentDue && surveyorPaymentDueTime ? true : countDownDetails?.visiblePage?.includes(4)
    return (
      <>
        <div className="sales-engine-title-with-button">
          <div className="sales-engine-title">
            {/* <CommonSalesEnginTitle title={stepperInfo?.activeStep?.name} /> */}

            {/* {statusInfo && (
              <div className="sales-engine-button-status">
                <span className="sales-engine-status inspection-payment-sales-engine-status">Status: {statusInfo}</span>
              </div>
            )} */}
            {/* temp commented, need for future use */}
            {/* {salesEngine &&
              salesEngine.agent === null &&
              !isSeller &&
              isBuyer &&
              (salesEngine?.seller?.role?.aliasName === userRoles.BOAT_MANUFACTURER ? (
                <p>{selectBuyer.dealer}</p>
              ) : stepperInfo?.activeStep?.step === 1 ? (
                <p>{selectBuyer.agent}</p>
              ) : stepperInfo?.activeStep?.step === 2 ? (
                <p>{selectBuyer.surveyor}</p>
              ) : (
                ''
              ))} */}
            {/* {salesEngine && salesEngine.surveyor === null && !salesEngine.isSurveyorSkip && !isSeller && isBuyer && stepperInfo?.activeStep?.step === 2 &&
              <p>
                Select surveyor to help you to survey your boat
              </p>
            } */}
            {!isPaymentDue && statusInfo && (
              <div className="sales-engine-button-status">
                <span className="sales-engine-status inspection-payment-sales-engine-status">Status: {statusInfo}</span>
              </div>
            )}
          </div>
          {this.renderButtons()}
        </div>
        <div style={{ margin: '60px 0 0' }}>
          {this.checkIfPaymentDue() && (
            <div className="error-message font-16"> Payment time limit is exceeded for this boat </div>
          )}
          {checkBoatPaymentStatus &&
            (isSeller || isBuyer || isAgent) &&
            countDownDetails &&
            countDownDetails?.visiblePage.includes(stepperInfo?.activeStep?.step) &&
            !boatPayment && (
              <div className="d-flex mt-10 justify-content-center process-countdown">
                <div className="payment-due-header">
                  {countDownDetails?.title}
                  <CommonTooltip
                    component={ctProps => (
                      <p data-tooltip-id={ctProps.id} data-tooltip-content={countDownDetails.desc} className="text-underline">
                        what is it ?
                      </p>
                    )}
                  />
                </div>
                <div className="d-flex">
                  <Countdown endTime={countDownDetails?.time} />
                </div>
              </div>
            )}
        </div>
      </>
    )
  }

  getProps = () => {
    const { salesEngine, currentUser, history } = this.props

    return {
      salesEngine,
      history,
      isAgent: currentUser.id === salesEngine?.agent?.id,
      isBuyer: currentUser.id === salesEngine?.buyer?.id,
      isSeller: currentUser.id === salesEngine?.seller?.id,
    }
  }

  render() {
    const { salesEngine, isLoading, stopSalesEngineProcess, currentUser } = this.props
    const { isBuyer, isSeller, isAgent } = this.getProps()
    const { stepperInfo, boat } = salesEngine

    const activeFifthStep = stepperInfo?.activeStep?.step === 5
    if (boat?.auctionRoomWinner === null && boat?.isAuctionRoomCreated && !boat?.auctionDeclined) {
      const url = viewBoatHandler(boat, true, true)
      window && window.location.replace(url)
      return
    }
    return (
      <>
        <Layout className="sales-engin-layout-design p-4">
          {isLoading || !currentUser?.id ? (
            <Loader isPageLoader />
          ) : (
            salesEngine?.id && (
              <Container fluid className={`${activeFifthStep ? 'h-100' : ''} pb-5 stepper-main-div-section`}>
                {salesEngine?.buySellProcess.includes(salesEngineStatus.stopProcess) ? (
                  <p>This Process has been stopped</p>
                ) : isBuyer || isSeller || isAgent ? (
                  <div className={activeFifthStep ? 'h-100 d-flex flex-column' : ''}>
                    <div
                      style={
                        isBuyer &&
                        stepperInfo?.activeStep?.step === 2 &&
                        !salesEngine.surveyorPayment &&
                        !salesEngine.surveyorAccepted
                          ? {
                              position: 'sticky',
                              top: document.querySelector('.header__bg')?.getBoundingClientRect().bottom,
                              zIndex: 11,
                              background: '#fff',
                            }
                          : {}
                      }
                    >
                      <div className="d-flex flex-row align-items-center mb-4">
                        <div style={{ marginRight: 40 }}>
                          <ButtonComponent
                            className="rounded-md btn-ghost"
                            onClick={() => this.props.history.push('/sales-engines')}
                          >
                            Back
                          </ButtonComponent>
                        </div>
                        <div className="stepper-info-main-section" ref={this.calculateHeaderWidth}>
                          {this.renderTabs(salesEngine)}
                        </div>
                      </div>

                      {/* {(isBuyer || isSeller) && <button
                          type="button"
                          name="stopProcess"
                          className="btn btn-outline-dark prev-sales-engine-btn"
                          value="Stop Process"
                          onClick={() => confirmSubmitHandler(
                            stopSalesEngineProcess,
                            { id: salesEngine?.id },
                            "Stop Process",
                            "Are you sure you want to stop process ? in case you choose to stop the process then it will not be able to retrieve it back"
                            )}
                        >
                          Stop Process
                      </button>} */}

                      {this.renderTitleWithButton(salesEngine)}
                    </div>

                    {salesEngine?.isSold && (
                      <div className="boat--sold-message">
                        Oops... You won't be able to continue purchasing this boat. Another user has already completed the
                        payment for the boat survey or has purchased the boat.
                      </div>
                    )}
                    {!salesEngine?.isSold && (
                      <div className={`${activeFifthStep ? 'h-100' : ''} d-flex flex-column width-100 m-auto`}>
                        <>
                          {stepperInfo?.activeStep?.step === 1 && <MyBoat {...this.getProps()} />}
                          {stepperInfo?.activeStep?.step === 2 && <SurveyMyBoat {...this.getProps()} />}
                          {stepperInfo?.activeStep?.step === 3 && <SurveyReport {...this.getProps()} />}
                          {stepperInfo?.activeStep?.step === 4 && (
                            <Payment {...this.getProps()} isPaymentDue={this.checkIfPaymentDue()} />
                          )}
                          {stepperInfo?.activeStep?.step === 5 &&
                            (salesEngine?.salesEngineStatus !== salesEngineStatus.boatReceived ? (
                              <Shipment
                                {...this.getProps()}
                                qrCodeContent={
                                  salesEngine?.isShipperSkip &&
                                  salesEngine?.qrCode &&
                                  getId(salesEngine?.seller) !== getId(currentUser) && (
                                    <div style={{ marginTop: 70 }}>
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-ghost mr-2 qrcode-btn"
                                        onClick={() => this.setState({ isQRCodeModalOpen: true })}
                                      >
                                        <FaQrcode />
                                        &nbsp;QR Code
                                      </button>

                                      <CommonModal
                                        className="sales-engine-header-help-dialog dashboard--table--help--section"
                                        open={this.state.isQRCodeModalOpen}
                                        close={() => this.setState({ isQRCodeModalOpen: false })}
                                        hideCloseIcon
                                        title={
                                          <button
                                            type="button"
                                            className="btn btn-outline-dark mr-2 qrcode-btn"
                                            disabled={this.props.isPdfLoading}
                                            onClick={() => {
                                              this.props.getModulePdf({
                                                id: getId(salesEngine),
                                                type: wishlistModuleEnum.BUYERSALESENGINEQR,
                                                currency: getLocalStorageItem('currentCurrency'),
                                              })
                                            }}
                                          >
                                            Download
                                          </button>
                                        }
                                      >
                                        <div className="px-10 pb-4">
                                          <img
                                            loading="lazy"
                                            src={salesEngine.qrCode}
                                            alt="qrcode"
                                            style={{ maxWidth: '50vw', maxHeight: '50vh' }}
                                          />
                                        </div>

                                        <ButtonComponent
                                          className="m-auto h-auto as--cancel--outline btn--padding btn--font "
                                          onClick={() => this.setState({ isQRCodeModalOpen: false })}
                                        >
                                          Close
                                        </ButtonComponent>
                                      </CommonModal>
                                    </div>
                                  )
                                }
                              />
                            ) : (
                              <h3 className="completed-sales-engine-label">{salesEngineStatus.boatReceived}!</h3>
                            ))}
                        </>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="mt-4 not--access--message--div">
                    <p className="not--access--message">The Sales Engine you trying to access is not available</p>
                  </div>
                )}
              </Container>
            )
          )}
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => ({
  salesEngine: state.salesEngineReducer?.salesEngine,
  getSalesEngineSuccess: state.salesEngineReducer?.getSalesEngineSuccess,
  isLoading: state.salesEngineReducer?.isLoading,
  isPdfLoading: state.pdfReducer?.isLoading,
  shipmentLocationAddedSuccess: state.salesEngineReducer?.shipmentLocationAddedSuccess,
  currentUser: state.loginReducer?.currentUser,
  salesEngineStaticContents: state?.salesEngineReducer?.salesEngineStaticContents,
})

const mapDispatchToProps = dispatch => ({
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  getCostEstimate: data => dispatch(getCostEstimate(data)),
  addSurveyOption: data => dispatch(addSurveyOption(data)),
  salesEngineAgents: data => dispatch(salesEngineAgents(data)),
  stopSalesEngineProcess: data => dispatch(stopSalesEngineProcess(data)),
  getSalesEngineStaticContent: () => dispatch(getSalesEngineStaticContent()),
  salesEngineStepChange: data => dispatch(salesEngineStepChange(data)),
  salesEnginPaymentInfo: data => dispatch(salesEnginPaymentInfo(data)),
  getModulePdf: data => dispatch(getModulePdf(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEngineProcess)
