import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { Box } from '@material-ui/core'
import { Row, Col, Container, Form, Card } from 'react-bootstrap'

import GoogleMap from '../../components/map/map'
import { requireMessage, requiredWords, validateWords } from '../../helpers/string'
import { Field, Loader } from '../../components'
import {
  createBoatShow,
  clearBoatShowFlag,
  getBoatShowById,
  updateBoatShow,
  clearEditBoatShow,
} from '../../redux/actions/boatShowAction'
import { dateStringFormate } from '../../util/utilFunctions'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import ErrorComponent from '../../components/error/errorComponent'
import { clearErrorMessageShow } from '../../redux/actions'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { getLatLngFromGeometric } from '../../helpers/jsxHelper'
import { AddressInputTypeEnum, MEDIA_NAME, userRoles } from '../../util/enums/enums'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { auctionTime, datesFromToday, disablePastDates } from '../../helpers/dateTimeHelper'
import { ButtonComponent } from '../../components/form/Button'
import { getAddMedia } from '../../helpers/s3FileUpload'

import './boatShow.scss'
import { stringValidation, urlValidation } from '../../helpers/yupHelper'
import { trimmedValue, maximumWordCount } from '../../helpers/string'
import TagsInput from '../dashboard/manageBoats/TagsInput'
class AddBoatShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      boatShow: {
        title: '',
        startDate: '',
        endDate: '',
        showDescription: '',
        endTime: '',
        boatshowImages: [],
        addMedia: {
          boatshowImages: [],
        },
        removeMedia: [],
        address: '',
        meta_tags: null
      },
      editBoatId: props.location && props.location.state && props.location.state.record,
      latLng: {},
      mediaUpdated: false,
      agree: false,
      termsModal: false,
      isButtonLoader: false,
    }
  }
  componentDidMount() {
    const { editBoatId } = this.state
    const { getBoatShowById, currentUser, history } = this.props
    if (editBoatId) {
      getBoatShowById(editBoatId)
    }

    const { role } = currentUser

    // if (role?.aliasName === userRoles.MEMBER) {
    //   history.push('/')
    // }
  }

  onClickAgree = () => {
    this.setState(prevState => ({
      agree: !prevState.agree,
    }))
  }

  static getDerivedStateFromProps(props, state) {
    const {
      clearBoatShowFlag,
      history,
      getSuccess,
      clearEditBoatShow,
      createSuccess,
      updateSuccess,
      createError,
      updateError,
    } = props
    if (getSuccess) {
      clearBoatShowFlag()
    }
    if (createSuccess || updateSuccess) {
      clearEditBoatShow()
      history.push('/manage-boat-shows')
      return {
        isButtonLoader: false,
      }
    } else if (createError || updateError) {
      popUpMessage('Error occurred', 'Error')
      return {
        isButtonLoader: false,
      }
    }
  }

  cancelHandler = () => {
    const { history, clearEditBoatShow } = this.props
    clearEditBoatShow()
    history.push('/manage-boat-shows')
  }

  prepareValue = () => {
    const { editBoatShow } = this.props
    const { boatShow, mediaUpdated, editBoatId } = this.state

    if (editBoatId && editBoatShow.hasOwnProperty('id')) {
      if (!mediaUpdated) {
        const { title, startDate, endDate, showDescription, boatshowImages, address } = editBoatShow

        const [oldAddress] = address

        boatShow.title = title
        boatShow.startDate = startDate
        boatShow.endDate = endDate
        boatShow.showDescription = showDescription
        boatShow.boatshowImages = boatshowImages

        this.setState({
          boatShow,
          latLng: getLatLngFromGeometric(oldAddress.geometricLocation),
          mediaUpdated: true,
        })
      }
      // delete extra field from boat object
      const { createdAt, __typename, ...filteredEditBoatShow } = editBoatShow

      return {
        ...filteredEditBoatShow,
        addMedia: boatShow.addMedia,
        removeMedia: [],
        endTime: boatShow.startDate,
      }
    }
  }

  render() {
    const { boatShow, latLng, editBoatId, isButtonLoader } = this.state
    const { createBoatShow, updateBoatShow, editBoatShow, isLoading, createError, errorMessage } = this.props
    const initValue = editBoatId && editBoatShow ? editBoatShow.hasOwnProperty('id') && this.prepareValue() : boatShow

    return (
      <DashboardLayout>
        <Container fluid>
          {editBoatId && !editBoatShow.hasOwnProperty('id') ? (
            <Loader isPageLoader />
          ) : (
            <Formik
              initialValues={{ ...initValue }}
              onSubmit={values => {
                const { clearErrorMessageShow } = this.props
                this.setState({ isButtonLoader: true })
                clearErrorMessageShow()

                const { boatshowImages, endTime, ...updatedValue } = values
                const input = {
                  ...updatedValue,
                  startDate: dateStringFormate(updatedValue.startDate),
                  endDate: dateStringFormate(updatedValue.endDate),
                  addMedia: getAddMedia(updatedValue.addMedia),
                }

                if (editBoatId) {
                  updateBoatShow(input)
                } else {
                  createBoatShow({ ...input })
                }
              }}
              validationSchema={Yup.object().shape({
                address: Yup.array(
                  Yup.object({
                    city: Yup.string(),
                  })
                )
                  .compact(v => !v.city || !v.state || !v.country)
                  .required(requireMessage('Address'))
                  .min(1, requireMessage('Address'))
                  .ensure(),
                title: stringValidation.required(requireMessage('Boat show title')),
                showDescription: stringValidation
                  .required(requireMessage('Boat Show Description'))
                  .ensure()
                  .test('match', requiredWords('Boat show description'), val => validateWords(val)),
                boatshowImages: Yup.array()
                  .min(1, requireMessage('Minimum 1 photos upload'))
                  .required(requireMessage('Boat Show Logo')),
                startDate: Yup.date()
                  .required(requireMessage('Start date'))
                  .min(new Date(), 'Start date must not be past date.')
                  .typeError('Invalid date.'),
                endDate: Yup.date()
                  .required(requireMessage('End date'))
                  .min(Yup.ref('startDate'), 'End date must be greater than start date and must not be past date.')
                  .typeError('Invalid date.'),
                endTime: stringValidation.required('End time must be greater than start time.'),
              })}
              render={({ errors, status, touched, values, setFieldValue, handleSubmit }) => (
                <Form>
                  <ErrorFocus />

                  <div className="pl-2 pt-3 pb-3">
                    <Box fontSize={'1.75rem'} fontWeight={700} className="map-title">
                      {`Post a Boat Show`}
                      {values?.adId && <span className="font-weight-400 font-14">Ad ID: {values.adId}</span>}
                    </Box>
                  </div>
                  <Row>
                    <Col xs={6} >
                      <div className="add-boat-map add-boat-show-map add-boat---show-map">
                        <GoogleMap
                          latLng={latLng}
                          fetch={result => {
                            if (result?.address1) {
                              setFieldValue('address', [{ ...result, type: AddressInputTypeEnum.COMMON_ADDRESS }])
                            } else {
                              setFieldValue('address', [])
                            }
                          }}
                          height={25}
                          width={100}
                          placeHolder="Boat Show Location"
                          columnName={'address'}
                          isError={errors.address}
                          isUpdate={editBoatId}
                          isFullGrid
                          crossCountryAllowed
                        ></GoogleMap>
                      </div>
                    </Col>
                    <Col xs={6} className="pl-0">
                      <Card className="boatShow-body h-100 m-0 border-top-right-radius-0 border-top-left-radius-0 border-bottom-right-radius-2 border-bottom-left-radius-2">
                        <Row className="boatShow-container addBoatShow-fields pt-3 pb-3 mt-auto mb-auto ">
                          <Col xs={12}  >
                            <Row  className='mobile-response'>
                              <Col xs={4}  className='custom-width' >
                                <div className="boatShow-field ">
                                  <Field
                                    label="Boat Show "
                                    id={'title'}
                                    name={'title'}
                                    type="text"
                                    value={values.title}
                                    onChangeText={e => {
                                      setFieldValue('title', e.target.value)
                                    }}
                                    onBlur={e => setFieldValue('title', trimmedValue(e.target.value))}
                                    limit={30}
                                    required
                                  />
                                  <ErrorMessage component="div" name="title" className="error-message" />
                                </div>
                              </Col>

                              <Col xs={4} className='custom-width'>
                                <div className="boatShow-field">
                                  <label className="required">Start Date</label>
                                  <Field
                                    name="startDate"
                                    type="select-date-time"
                                    isUpdate={editBoatId}
                                    isValidDate={datesFromToday}
                                    isUtc={false}
                                    value={values.startDate}
                                    onChange={value => {
                                      setFieldValue('startDate', value)
                                      setFieldValue('endTime', value)
                                      if (auctionTime(values.endDate) === auctionTime(value)) {
                                        setFieldValue('endTime', '')
                                      }
                                    }}
                                  />
                                  <ErrorMessage component="div" name="startDate" className="error-message" />
                                </div>
                              </Col>

                              <Col xs={4} className='custom-width'>
                                <div className="boatShow-field">
                                  <label className="required">End Date</label>
                                  <Field
                                    name="endDate"
                                    type="select-date-time"
                                    isUpdate={editBoatId}
                                    isValidDate={datesFromToday}
                                    isUtc={false}
                                    value={values.endDate}
                                    onChange={value => {
                                      setFieldValue('endDate', value)
                                      setFieldValue('endTime', value)
                                      if (auctionTime(values.startDate) === auctionTime(value)) {
                                        setFieldValue('endTime', '')
                                      }
                                    }}
                                  />
                                  {values.startDate && values.endDate && (
                                    <ErrorMessage component="div" name="endTime" className="error-message" />
                                  )}
                                  <ErrorMessage component="div" name="endDate" className="error-message" />
                                </div>
                              </Col>
                              <Col xs={12}>
                                <div className="boatShow-field">
                                  <Field
                                    label="Boat Show Description"
                                    id={'showDescription'}
                                    name={'showDescription'}
                                    type="textarea"
                                    value={values.showDescription}
                                    required
                                    onChangeText={e => {
                                      setFieldValue('showDescription', e.target.value)
                                    }}
                                    onBlur={e => setFieldValue('showDescription', trimmedValue(e.target.value))}
                                    onKeyDown={maximumWordCount}
                                  />
                                </div>
                                <ErrorMessage component="div" name="showDescription" className="error-message" />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} className="mt-3 mb-3">
                            <hr></hr>
                          </Col>
                          <Col xs={12}>
                            <div className="d-flex flex-wrap justify-content-center width-100 m-auto h-100 overflow-hidden">
                              <div className="text-center m-auto boat--show--logo--add">
                                <Field
                                  label="Boat Show Images"
                                  id="boatshowImages"
                                  name="boatshowImages"
                                  type="multi-upload-image"
                                  setFieldValue={setFieldValue}
                                  values={values.boatshowImages}
                                  deleteIds={values.removeMedia}
                                  addMedia={values.addMedia}
                                  mediaName={MEDIA_NAME.BOATSHOWIMAGE}
                                  required
                                  maxImageSize={0}
                                />
                                <ErrorMessage component="div" name="boatshowImages" className="error-message" />
                              </div>
                            </div>
                          </Col>
                          <Col xs={12}>
                            <TagsInput 
                              value={values?.meta_tags}
                              handleChange={(value) => {
                                setFieldValue('meta_tags', value.join(','))
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <br />
                  {createError && <ErrorComponent errors={errorMessage} />}
                  <div className="d-flex justify-content-center mb-3">
                    <ButtonComponent
                      variant="outline"
                      color="success"
                      className=" h-auto btn--padding btn--font mr-3 add--boat--show--btn"
                      onClick={handleSubmit}
                      disabled={isButtonLoader}
                      loader={isButtonLoader}
                    >
                      {'Save'}
                    </ButtonComponent>

                    <ButtonComponent
                      variant="outline"
                      color="cancel"
                      className=" h-auto btn--padding btn--font add--boat--show--btn"
                      onClick={this.cancelHandler}
                    >
                      {' '}
                      {'Cancel'}
                    </ButtonComponent>
                  </div>
                </Form>
              )}
            ></Formik>
          )}
        </Container>
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  editBoatShow: state.boatShowReducer && state.boatShowReducer.editBoatShow,
  createSuccess: state.boatShowReducer && state.boatShowReducer.createSuccess,
  createError: state.boatShowReducer && state.boatShowReducer.createError,
  updateSuccess: state.boatShowReducer && state.boatShowReducer.updateSuccess,
  updateError: state.boatShowReducer && state.boatShowReducer.updateError,
  getSuccess: state.boatShowReducer && state.boatShowReducer.getSuccess,
  isLoading: state.boatShowReducer && state.boatShowReducer.isLoading,
  errorMessage: state.errorReducer.errorMessage,
  currentUser: state.loginReducer.currentUser,
})

const mapDispatchToProps = dispatch => ({
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  createBoatShow: data => dispatch(createBoatShow(data)),
  clearBoatShowFlag: () => dispatch(clearBoatShowFlag()),
  getBoatShowById: data => dispatch(getBoatShowById(data)),
  updateBoatShow: data => dispatch(updateBoatShow(data)),
  clearEditBoatShow: () => dispatch(clearEditBoatShow()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBoatShow)
