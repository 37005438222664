import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import { Grid } from '@material-ui/core'

import { SurveyorSubmittedReport, SellerInformation, UserInformationCard, Field } from '../../../components'
import { getSurveyorReport, getDocumentLinks, getDocumentUrl, buyerReviewSurveyReport } from '../../../redux/actions'
import { CommonSalesEnginTitle } from '../../../components/salesEngine/CommonSalesEnginTitle'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import BoatInformation from '../../../components/salesEngine/BoatInformation'

class SurveyReport extends Component {
  componentDidMount() {
    const { getSurveyorReport, salesEngine, getDocumentLinks } = this.props

    getSurveyorReport({ surveyorId: salesEngine.surveyor && salesEngine.surveyor.user.id, salesEngineId: salesEngine.id })
    getDocumentLinks({ id: salesEngine.id, type: 'Surveyor' })
  }

  render() {
    const { salesEngine, surveyorReport, isSeller, history, getDocumentUrl, buyerReviewSurveyReport, isBuyer } = this.props

    return (
      <>
        <div>
          <Container fluid className="pl-0 pr-0">
            {isBuyer && (
              <div className="mt-5 d-flex justify-content-center labelResetStyles">
                <button
                  className="btn btn-report-submitted"
                  disabled={salesEngine.buyerReviewSurveyReport}
                  onClick={() => {
                    salesEngine?.id && !salesEngine.buyerReviewSurveyReport && buyerReviewSurveyReport({ id: salesEngine.id })
                  }}
                >
                  <Field
                    type="checkbox"
                    value={salesEngine.buyerReviewSurveyReport}
                    disable={salesEngine.buyerReviewSurveyReport}
                  />
                  To enable the boat payment, simply check the box
                </button>
              </div>
            )}

            {/* Surveyor Report */}
            {salesEngine && surveyorReport && isBuyer && (
              <SurveyorSubmittedReport
                salesEngine={salesEngine}
                surveyorReport={surveyorReport}
                getDocumentUrl={getDocumentUrl}
              />
            )}

            {salesEngine.surveyor && (
              <div className="surveyor--rep--main">
                <CommonSalesEnginTitle
                  isReport
                  title="Surveyor Detail"
                  className="surveyor--submitted--title"
                  otherInformation={`<span class="surveyor--submitted--date">
                      Accepted Date : ${salesEngine.surveyorRequestAccept && formattedDate(salesEngine.surveyorRequestAccept)}
                    </span>`}
                />
                <UserInformationCard
                  className="surveyor--report--surveyor--detail xyz"
                  isSurveyor
                  isSurveyorWithBranch
                  userInfo={salesEngine.surveyor.user}
                  surveyorInfo={salesEngine.surveyor}
                  salesEngine={salesEngine}
                  isBuyer={isBuyer}
                  history={history}
                  isSurveyReviewEnable
                />
              </div>
            )}

            <Grid container spacing={3}>
              <BoatInformation salesEngine={salesEngine} />

              <SellerInformation salesEngine={salesEngine} isSeller={isSeller} history={history} isBuyer={isBuyer} />
            </Grid>
          </Container>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  surveyorReport: state.salesEngineReducer?.surveyorReport,
})

const mapDispatchToProps = dispatch => ({
  getSurveyorReport: data => dispatch(getSurveyorReport(data)),
  getDocumentLinks: data => dispatch(getDocumentLinks(data)),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  buyerReviewSurveyReport: data => dispatch(buyerReviewSurveyReport(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveyReport)
