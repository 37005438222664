import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { defaultProfileIcon } from '../../util/enums/enums'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { getId, getOnlineUsers } from '../../util/utilFunctions'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import styles from './ShipperRequestList.module.scss'
import IconDownloadNew from '../../assets/images/salesEngine/download'
import ChatCreateUse from '../chats/ChatCreateUse'

export const ShipperRequestList = props => {
  const { userInfo, price, salesEngine, shipperAcceptRequest, proposalDocument, distance, getDocumentUrl, history } = props
  const { city, country } = salesEngine?.shipmentLocation

  const onlineUsers = useSelector(state => state.chatReducer.onlineUsers)
  const dispatch = useDispatch()

  useEffect(() => {
    getId(userInfo) && getOnlineUsers([getId(userInfo)], payload => dispatch({ type: SET_ONLINE_USERS, payload }))
  }, [userInfo])

  return (
    <div className={styles.cardWrp}>
      <div className={styles.card}>
        <div className={styles.companyName}>{userInfo.companyName}</div>

        <div className={styles.cardCols}>
          <div className={styles.cardCol}>
            <div className={styles.userPicWrp}>
              <div className={styles.userPic}>
                <img src={userInfo.image?.url || defaultProfileIcon} alt={userInfo.firstName} />
                <div className="stepper-user-online">
                  <div className={cn('online-div-user', { disconnect: !onlineUsers[getId(userInfo)] })} />
                </div>
              </div>
            </div>

            <div className={styles.userName}>{userInfo.firstName}</div>
            <div>
              <ChatCreateUse id={userInfo.id} image={userInfo.image?.url} />
            </div>
          </div>

          <div className={styles.cardCol}>
            <div>
              <div className={styles.fieldValue}>{(+String(distance).replace(/[^0-9]/g, '')).toLocaleString() + ' km'}</div>
              <div className={styles.fieldLabel}>Shipper to Boat</div>
            </div>

            <div className={styles.hr} />

            <div>
              <div className={styles.fieldValue}>
                <CurrencyContextConsumer>
                  {({ currentCurrency }) => price && getConvertedPrice(price, currentCurrency)}
                </CurrencyContextConsumer>
              </div>
              <div className={styles.fieldLabel}>Delivery Cost</div>
            </div>
          </div>
        </div>

        <div className={styles.cardBtns}>
          {proposalDocument && (
            <button type="button" className="btn btn-ghost rounded-full" onClick={() => getDocumentUrl(proposalDocument?.id)}>
              Download Proposal <IconDownloadNew height="1em" />
            </button>
          )}
          <button
            type="button"
            className="btn btn-ghost rounded-full"
            onClick={() => shipperAcceptRequest({ shipperId: userInfo.id, id: salesEngine.id })}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  )

  // return (
  //   <div className={`shipper--request--list`}>
  //     <div className="shipper--request--image">
  //       <img
  //         className="rounded-circle"
  //         src={userInfo.image?.url || defaultProfileIcon}
  //         alt="profile icon"
  //         onClick={() => redirectToUserProfile(userInfo, history)}
  //       />
  //     </div>
  //     <div className="shipper--list--name">
  //       {userInfo.firstName} {userInfo.lastName}
  //     </div>
  //     <div className="shipper--list--name--company-name">{userInfo.companyName}</div>
  //     <div className="shipper--list--name--city">{cityCountryNameFormatter(city, country)}</div>

  //     {userInfo && (
  //       <div className="shipper-list-company-cost text-center">
  //         <CurrencyContextConsumer>
  //           {({ currentCurrency }) => (
  //             <>
  //               <span className="text-nowrap" style={{ fontSize: '1.1em' }}>
  //                 Shipment Cost
  //               </span>{' '}
  //               <span className="text-nowrap" style={{ fontSize: '1.1em' }}>
  //                 {price && getConvertedPrice(price, currentCurrency)}
  //               </span>
  //             </>
  //           )}
  //         </CurrencyContextConsumer>
  //       </div>
  //     )}
  //     <div className="shipper--cost--with--distance text-center d-flex flex-column">
  //       <div className="mt-1 from--to--city--info">
  //         From:{' '}
  //         <span>
  //           {salesEngine && salesEngine.boat && salesEngine.boat.address.length > 0 && salesEngine.boat.address[0].city},{' '}
  //           {salesEngine && salesEngine.boat && salesEngine.boat.address.length > 0 && salesEngine.boat.address[0].country}
  //         </span>
  //       </div>
  //       <div className="mt-1 from--to--city--info">
  //         To:{' '}
  //         <span>
  //           {city}, {country}
  //         </span>
  //       </div>
  //     </div>
  //     {distance && (
  //       <div className="shipper--distance--value text-center">
  //         Shipper to boat distance:{' '}
  //         <span className="distance--km">
  //           {String(distance)
  //             .replace(/[^0-9]/g, '')
  //             .toLocaleString('en-US') + 'km'}
  //         </span>
  //       </div>
  //     )}
  //     <div className="shipper--list--cost-btn">
  //       {proposalDocument && (
  //         <button
  //           type="button"
  //           className={`w-auto btn btn-ghost mb-3 proposal--btn`}
  //           onClick={() => getDocumentUrl(proposalDocument?.id)}
  //         >
  //           Download Proposal
  //           <img
  //             src={require('../../assets/images/Arrow/download-arrow.svg')}
  //             alt="download icon"
  //             className="main--download--icon"
  //             height="15px"
  //           />
  //           <img
  //             src={require('../../assets/images/Arrow/white-arrow.svg')}
  //             alt="download icon"
  //             className="hover--download--icon"
  //             height="15px"
  //           />
  //         </button>
  //       )}
  //       <button
  //         type="button"
  //         className={`w-auto btn btn-ghost m-auto select--btn`}
  //         onClick={() => shipperAcceptRequest({ shipperId: userInfo.id, id: salesEngine.id })}
  //       >
  //         Select
  //       </button>
  //     </div>
  //   </div>
  // )
}
