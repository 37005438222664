import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

import { defaultProfileIcon, shareModule } from '../../util/enums/enums'
import { redirectToUserProfile, mileToKilometers, getAddress, cityCountryNameFormatter } from '../../helpers/jsxHelper'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import SharePopup from '../share/SharePopup'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { SaleEngineLabelAndValue } from './SaleEngineLabelAndValue'
import { ButtonComponent } from '../form/Button'
import '../../containers/salesEngine/SurveyorList.scss'
import { nameFormatter, textTruncate } from '../../helpers/string'
import '../../containers/salesEngine/process/SalesEngineStepper.scss'
import ChatUserCreate from '../chats/ChatCreateUse'
import { getId, getOnlineUsers } from '../../util/utilFunctions'
import { connect } from 'react-redux'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import classNames from 'classnames'

export const SurveyorList = connect(
  state => ({ onlineUsers: state.chatReducer.onlineUsers }),
  dispatch => ({ setOnlineUsers: users => dispatch({ type: SET_ONLINE_USERS, payload: users }) })
)(
  withRouter(props => {
    const {
      setOnlineUsers,
      onlineUsers,
      userInfo,
      showSurveyorButtons,
      requestSurveyorHandler,
      declineSurveyorHandler,
      declineSurveyorLoading,
      isSurveyorRequested,
      proposalInfo,
      showShipperButtons,
      salesEngine,
      shipperAcceptRequest,
      history,
      assignSurveyorLoading,
      index,
      requestedSurveyorIndex,
      declineSurveyorIndex,
      isBranchList,
      inProfile,
      isBranchImage,
      isSalesEngine,
    } = props

    const [selectedIndex, setSelectedIndex] = React.useState(null)

    const handleClick = index => {
      setSelectedIndex(selectedIndex !== index ? index : null)
    }

    useEffect(() => {
      getId(userInfo) && getOnlineUsers([getId(userInfo)], setOnlineUsers)
    }, [getId(userInfo)])

    const { officeAddress, commonAddress } = getAddress(userInfo?.branch?.address)
    const { city, country } = commonAddress.address1 ? commonAddress : officeAddress
    const branchDetails = getAddress(userInfo?.address)
    const cityName = inProfile ? branchDetails.commonAddress.city || branchDetails.officeAddress.city : city
    const countryName = inProfile ? branchDetails.commonAddress.country || branchDetails.officeAddress.country : country
    const position = inProfile ? userInfo.position?.alias : userInfo?.branch?.position?.alias
    return (
      <>
        <div className="surveyor-list-nearest between-information">
          <div className="px-4 mb-3 surveyor-list-surveyor-name cursor-pointer color-black font-weight-bold text-center">
            {!isBranchList && isBranchImage ? userInfo.companyName : userInfo?.branchName}
          </div>
          <div className="d-flex align-items-center w-100 justify-content-around">
            <div className="d-flex flex-column align-items-center flex-1">
              <div className="surveyor-list-image cursor-pointer position-relative">
                <img
                  className="rounded-circle"
                  src={
                    (isBranchImage
                      ? userInfo?.branch?.isMainBranch
                        ? userInfo?.image?.url
                        : userInfo?.branch?.image?.url
                      : inProfile && userInfo?.isMainBranch
                      ? userInfo?.user?.image?.url
                      : userInfo.image?.url) || defaultProfileIcon
                  }
                  alt="profile icon"
                  onClick={() => redirectToUserProfile(inProfile ? userInfo.user : userInfo, history)}
                />
                <div className="stepper-user-online">
                  <div
                    className={classNames('online-div-user', {
                      disconnect: !onlineUsers[getId(userInfo)],
                    })}
                  ></div>
                </div>
              </div>

              <div
                onClick={() => redirectToUserProfile(inProfile ? userInfo.user : userInfo, history)}
                className="surveyor-list-surveyor-name cursor-pointer color-black font-weight-bold text-center"
              >
                {userInfo?.branch?.isMainBranch
                  ? userInfo.firstName.split(' ')[0]
                  : userInfo?.branch?.contactName.split(' ')[0] || userInfo?.contactName?.split(' ')[0]}
              </div>

              <div className="inner-shippers-list d-flex">
                <ChatUserCreate id={userInfo.id} image={userInfo.image?.url} />

                {/* <SharePopup
                  handleClick={() => handleClick(userInfo.id)}
                  index={userInfo.id}
                  selectedIndex={selectedIndex}
                  moduleId={userInfo.id}
                  isShipperList
                  moduleType={shareModule.USER}
                  sharedUrl={redirectToUserProfile(userInfo, null, null, true, true)}
                  shareBlack
                /> */}
              </div>

              <span className="text-center font-16">{position}</span>

              {/* <span className="surveyor-list-country-data my-1">{cityCountryNameFormatter(cityName, countryName)}</span> */}
            </div>
            <div className="d-flex flex-column align-items-center flex-1">
              {userInfo?.branch && (
                <div className="surveyor-list-cost-with-distance">
                  {isSalesEngine && (
                    <div className="surveyor-list-company-cost">
                      <CurrencyContextConsumer>
                        {({ currentCurrency }) => (
                          <div className="d-flex flex-column align-items-center">
                            <strong
                              style={{ width: 'max-content' }}
                              className="color-black cost-of-surveyor-value small-screen-font-15"
                            >
                              {`${
                                userInfo?.branch?.pricePerFt && getConvertedPrice(userInfo.branch.pricePerFt, currentCurrency)
                              } / ft`}
                            </strong>
                            <p className="surveyor--list-company-cost fontSize12 mb-0">Survey cost</p>
                          </div>
                          // <SaleEngineLabelAndValue
                          //   className="surveyor--list-company-cost"
                          //   valueClass="cost-of-surveyor-value"
                          //   label="Cost of surveyor"
                          //   value={`${userInfo?.branch?.pricePerFt && getConvertedPrice(userInfo.branch.pricePerFt, currentCurrency)
                          //     } / ft`}
                          // />
                        )}
                      </CurrencyContextConsumer>
                    </div>
                  )}
                  <hr width="50%" className="mx-auto" />
                  <div className="surveyor-list-company-distance">
                    <div className="d-flex flex-column align-items-center">
                      <strong className="color-black cost-of-surveyor-value text-center small-screen-font-15">
                        {`${mileToKilometers(userInfo?.branch?.distance)} km`}
                      </strong>
                      <p className="surveyor--list-company-cost text-center fontSize12">Distance to the boat</p>
                    </div>
                    {/* <SaleEngineLabelAndValue
                    className="surveyor--list-company-cost"
                    valueClass="cost-of-surveyor-value"
                    label="Distance to the boat"
                    value={`${mileToKilometers(userInfo?.branch?.distance)} km`}
                  /> */}
                  </div>
                </div>
              )}

              {/* {isBranchList && <span className="surveyor-list-contact-name-data">{userInfo?.contactName}</span>} */}
              {/* {isBranchList && (
              <CurrencyContextConsumer>
                {({ currentCurrency }) => (
                  <span className="font-18 font-black font-bold">
                    {getConvertedPrice(userInfo?.pricePerFt, currentCurrency)} / ft
                    </span>
                )}
              </CurrencyContextConsumer>
            )} */}
              {/* {!userInfo?.branch && (
                <span className="surveyor-list-country-data mt-3 px-2 color-black">
                  {cityCountryNameFormatter(cityName, countryName)}
                </span>
              )} */}
              {userInfo?.isMainBranch && <p className="mb-0 color-black font-bold">Main Branch</p>}
            </div>
          </div>
          <div className="surveyor-list-country-data mt-10 mb-10 px-2 color-black text-center">
            {cityCountryNameFormatter(cityName, countryName)}
          </div>
          <div className="surveyor-list-cost-btn d-flex justify-content-center">
            {showSurveyorButtons && (
              <>
                <ButtonComponent
                  className={`${isSurveyorRequested(userInfo.branch?.id) ? 'surveyor--requested--btn--color' : ''} w-auto`}
                  color="danger"
                  variant="contained"
                  onClick={() => requestSurveyorHandler(userInfo.branch?.id, index)}
                  disabled={isSurveyorRequested(userInfo.branch?.id) || assignSurveyorLoading}
                  loader={requestedSurveyorIndex === index && assignSurveyorLoading}
                >
                  {isSurveyorRequested(userInfo.branch?.id) ? 'Requested' : 'Request'}
                </ButtonComponent>

                {isSurveyorRequested(userInfo.branch?.id) && (
                  <ButtonComponent
                    color="danger"
                    className="w-auto"
                    onClick={() => declineSurveyorHandler(userInfo?.branch?.id, index)}
                    loader={declineSurveyorIndex === index && declineSurveyorLoading}
                    disabled={declineSurveyorIndex === index && declineSurveyorLoading}
                  >
                    {'Cancel'}
                  </ButtonComponent>
                )}
              </>
            )}
            {showShipperButtons && (
              <button
                type="button"
                className={`w-auto btn btn-sales-engine-secondary`}
                onClick={() => shipperAcceptRequest(proposalInfo.shipper.id)}
              >
                {'Accept'}
              </button>
            )}
          </div>
        
          {/* <div className="surveyor-list-image cursor-pointer position-relative">
          <img
            className="rounded-circle"
            src={
              (isBranchImage
                ? userInfo?.branch?.isMainBranch
                  ? userInfo?.image?.url
                  : userInfo?.branch?.image?.url
                : inProfile && userInfo?.isMainBranch
                ? userInfo?.user?.image?.url
                : userInfo.image?.url) || defaultProfileIcon
            }
            alt="profile icon"
            onClick={() => redirectToUserProfile(inProfile ? userInfo.user : userInfo, history)}
          />
          <div className="stepper-user-online">
            <div className={classNames("online-div-user", {
              'disconnect': !onlineUsers[getId(userInfo)],
            })}></div>
          </div>
        </div>

        <div
          onClick={() => redirectToUserProfile(inProfile ? userInfo.user : userInfo, history)}
          className="surveyor-list-surveyor-name cursor-pointer"
        >
          {userInfo?.branch?.isMainBranch
            ? nameFormatter([userInfo.firstName, userInfo.lastName])
            : userInfo?.branch?.contactName}
        </div>

        <div className="surveyor-list-company-name color-black">
          {!isBranchList && isBranchImage ? userInfo.companyName : userInfo?.branchName}
        </div>

        <div className="surveyor-list-company-icon d-flex">
          <ChatUserCreate id={userInfo.id} image={userInfo.image?.url}/>

          <SharePopup
            handleClick={() => handleClick(userInfo.id)}
            index={userInfo.id}
            selectedIndex={selectedIndex}
            moduleId={userInfo.id}
            isShipperList
            moduleType={shareModule.USER}
            sharedUrl={redirectToUserProfile(userInfo, null, null, true, true)}
          />
        </div> */}
        </div>
      </>
    )
  })
)
