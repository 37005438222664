import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import {
  getSalesEngineBySurveyor,
  surveyorAcceptBuyer,
  surveyorDeclineBuyer,
  clearSalesEngineSurveyorDashboardFlags,
  getSurveyDocumentData,
  userVisitSalesEngineDashboard,
} from '../../../redux/actions/salesEngineAction'
import { PaginationBar, Loader } from '../../../components'
import { pagination, tableText } from '../../../util/enums/enums'

import { SuccessNotify } from '../../../helpers/notification'
import { SalesEngineDashboardCardListing } from '../../../components/salesEngine/SalesEngineDashboardCardListing'
import { CommonSalesEnginTitle } from '../../../components/salesEngine/CommonSalesEnginTitle'
import { getDocumentUrl } from '../../../redux/actions'

import '../../../styles/manageDashboardTableResponsive.scss'
import { getModulePdf } from '../../../redux/actions/pdfAction'
import { getPagesValue } from '../../../helpers/jsxHelper'
import { DashboardSearchSection } from '../../../components/salesEngine/DashboardSearchSection'
import { SkeletonLoader } from '../../../components/loader/SkeletonLoader'
import { CommonModal } from '../../../components/modal/commonModal'
import { clear_getStaticPages, getStaticPages } from '../../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'
import { ButtonComponent } from '../../../components/form/Button'

class SurveyorSalesEngine extends Component {
  state = {
    page: null,
    searchTerm: '',
    helpModalShow: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      surveyorAcceptSuccess,
      clearSalesEngineSurveyorDashboardFlags,
      getSalesEngineBySurveyor,
      surveyorDeclineSuccess,
      branchId,
      getBySurveyorSuccess,
      userVisitSalesEngineDashboard,
    } = nextProps

    if (getBySurveyorSuccess) {
      userVisitSalesEngineDashboard({ branchId })
    }

    if (surveyorAcceptSuccess) {
      SuccessNotify('Request accepted successfully')
      clearSalesEngineSurveyorDashboardFlags()
      getSalesEngineBySurveyor({ page: pagination.PAGE_COUNT, limit: pagination.SURVEYOR_PAGE_LIMIT, branchId })
    }
    if (surveyorDeclineSuccess) {
      SuccessNotify('Request Declined Successfully.')
      clearSalesEngineSurveyorDashboardFlags()
      getSalesEngineBySurveyor({ page: pagination.PAGE_COUNT, limit: pagination.SURVEYOR_PAGE_LIMIT, branchId })
    }

    return null
  }

  componentDidMount() {
    const { getSalesEngineBySurveyor, branchId, getSurveyDocumentData, getStaticPages } = this.props
    getSalesEngineBySurveyor({
      page: getPagesValue(),
      limit: pagination.SURVEYOR_PAGE_LIMIT,
      branchId,
    })
    getSurveyDocumentData()
    getStaticPages({ slug: 'do-you-need-help-surveyor-704' })
  }

  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }

  submitReport = data => {
    const { branchId } = this.props
    if (data?.id && branchId) {
      this.props.history.push(`/add-surveyor-report/${data.id}/${branchId}`, {
        salesEngineId: data.id,
        isReportUploaded: data.isReportUploaded,
        branchId,
      })
    }
  }

  setSearchValue = value => {
    this.setState({ searchTerm: value })
  }

  helpModalHandler = () => this.setState(prevState => ({ helpModalShow: !prevState.helpModalShow }))

  render() {
    const {
      salesEngines,
      surveyorAcceptBuyer,
      surveyorDeclineBuyer,
      getSalesEngineBySurveyor,
      surveyorTotal,
      currentUser,
      isLoading,
      getDocumentUrl,
      getModulePdf,
      surveyDocument,
      history,
      branchId,
    } = this.props
    const { searchTerm, helpModalShow } = this.state

    return (
      <Fragment>
        <>
          <div className="manage-dashboard-table">
            <div className="agent--main--title--section pb-0 d-flex">
              <div style={{ color: 'black', fontSize: 22, fontWeight: 500 }}>{`${surveyorTotal || 0} Boat inspection requests`}</div>
              <DashboardSearchSection
                action={getSalesEngineBySurveyor}
                branchId={branchId}
                setSearchValue={this.setSearchValue}
              />
            </div>
            <div className="agent--main--title--section pb-0">
              <div className="middle--content-table">
                <div className="do-you-help-content" onClick={this.helpModalHandler}>
                  <span className="cursor-pointer">{tableText.needText}</span>
                </div>
              </div>

              <CommonModal
                className="sales-engine-header-help-dialog dashboard--table--help--section w-90"
                open={helpModalShow}
                close={this.helpModalHandler}
                title="Help"
              >
                <p
                  className="mb-3 dashboard--table--help--desc"
                  dangerouslySetInnerHTML={{ __html: get(this.props.staticPages, 'data[0].pageContent', '') || '' }}
                />

                <ButtonComponent
                  className="m-auto h-auto as--cancel--outline btn--padding btn--font "
                  onClick={this.helpModalHandler}
                >
                  Close
                </ButtonComponent>
              </CommonModal>
            </div>
            {/* {salesEngines.length > 0 && (
              <div className="total--request--div pt-0">
                <p className="total--request">{`Total ${surveyorTotal} Requests`}</p>
              </div>
            )} */}
            {isLoading ? (
              <SkeletonLoader itemsLength={10} />
            ) : salesEngines?.length ? (
              <SalesEngineDashboardCardListing
                salesEngines={salesEngines}
                accept={surveyorAcceptBuyer}
                decline={surveyorDeclineBuyer}
                submitReport={this.submitReport}
                history={history}
                getDocumentUrl={getDocumentUrl}
                currentUserId={currentUser.id}
                getModulePdf={getModulePdf}
                surveyDocument={surveyDocument}
                className="surveyor-layout-fix"
                isLoading={isLoading}
                branchId={branchId}
                location={history.location}
                isSurveyor
              />
            ) : (
              !isLoading && <div className="no--record-found">No record Found.</div>
            )}
          </div>
        </>
        {!isLoading && surveyorTotal > pagination.SURVEYOR_PAGE_LIMIT && (
          <div className="mt-3">
            <PaginationBar
              action={getSalesEngineBySurveyor}
              value={{ branchId }}
              totalRecords={surveyorTotal}
              pageLimit={pagination.SURVEYOR_PAGE_LIMIT}
              searchTerm={searchTerm}
            />
          </div>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  salesEngines: state.salesEngineReducer.salesEngines,
  isLoading: state.salesEngineReducer.isLoading,
  surveyorTotal: state.salesEngineReducer.surveyorTotal,
  surveyorAcceptSuccess: state.salesEngineReducer.surveyorAcceptSuccess,
  surveyorDeclineSuccess: state.salesEngineReducer.surveyorDeclineSuccess,
  surveyDocument: state.salesEngineReducer?.surveyDocument,
  getBySurveyorSuccess: state.salesEngineReducer?.getBySurveyorSuccess,
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getSalesEngineBySurveyor: (data, isSearchAction) => dispatch(getSalesEngineBySurveyor(data, isSearchAction)),
  surveyorAcceptBuyer: data => dispatch(surveyorAcceptBuyer(data)),
  surveyorDeclineBuyer: data => dispatch(surveyorDeclineBuyer(data)),
  clearSalesEngineSurveyorDashboardFlags: () => dispatch(clearSalesEngineSurveyorDashboardFlags()),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  getModulePdf: data => dispatch(getModulePdf(data)),
  getSurveyDocumentData: () => dispatch(getSurveyDocumentData()),
  userVisitSalesEngineDashboard: data => dispatch(userVisitSalesEngineDashboard(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveyorSalesEngine)
